export default {
  'slot1': "7424984203",
  'slot2': "9859575852",
  'slot3': "9668004161",
  "cid":94,
  'firebaseConfig': {
  apiKey: "AIzaSyB-pJRfFeOA-Nrg3qippivr29TlrfvbzHg",
    authDomain: "business1-network.firebaseapp.com",
    projectId: "business1-network",
    storageBucket: "business1-network.appspot.com",
    messagingSenderId: "847013664996",
    appId: "1:847013664996:web:c699ef6b69ff917a2cf4f1",
    measurementId: "G-3MX2D562QY"
  }
}